:root {
  --svg-color: #efc91d;
}

@font-face {
  font-family: Noor;
  src: url("../fonts/Noor-Bold.eot");
  src: local(Noor Bold), local(Noor-Bold), url("../fonts/Noor-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Noor-Bold.woff2") format("woff2"), url("../fonts/Noor-Bold.woff") format("woff"), url("../fonts/Noor-Bold.ttf") format("truetype"), url("../fonts/Noor-Bold.svg#Noor-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Noor;
  src: url("../fonts/Noor-Light.eot");
  src: local(Noor Light), local(Noor-Light), url("../fonts/Noor-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Noor-Light.woff2") format("woff2"), url("../fonts/Noor-Light.woff") format("woff"), url("../fonts/Noor-Light.ttf") format("truetype"), url("../fonts/Noor-Light.svg#Noor-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rajdhani;
  src: url("../fonts/Rajdhani-Bold.eot");
  src: local(Rajdhani Bold), local(Rajdhani-Bold), url("../fonts/Rajdhani-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Rajdhani-Bold.woff2") format("woff2"), url("../fonts/Rajdhani-Bold.woff") format("woff"), url("../fonts/Rajdhani-Bold.ttf") format("truetype"), url("../fonts/Rajdhani-Bold.svg#Rajdhani-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rajdhani;
  src: url("../fonts/Rajdhani-Medium.eot");
  src: local(Rajdhani Medium), local(Rajdhani-Medium), url("../fonts/Rajdhani-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Rajdhani-Medium.woff2") format("woff2"), url("../fonts/Rajdhani-Medium.woff") format("woff"), url("../fonts/Rajdhani-Medium.ttf") format("truetype"), url("../fonts/Rajdhani-Medium.svg#Rajdhani-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Helvetica;
  src: url("../fonts/Helvetica-Light.eot");
  src: local(Helvetica Light), local(Helvetica-Light), url("../fonts/Helvetica-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Helvetica-Light.woff2") format("woff2"), url("../fonts/Helvetica-Light.woff") format("woff"), url("../fonts/Helvetica-Light.ttf") format("truetype"), url("../fonts/Helvetica-Light.svg#Helvetica-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Rajdhani;
  src: url("../fonts/Rajdhani-Regular.eot");
  src: local(Rajdhani Regular), local(Rajdhani-Regular), url("../fonts/Rajdhani-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Rajdhani-Regular.woff2") format("woff2"), url("../fonts/Rajdhani-Regular.woff") format("woff"), url("../fonts/Rajdhani-Regular.ttf") format("truetype"), url("../fonts/Rajdhani-Regular.svg#Rajdhani-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Verdana;
  src: url("../fonts/Verdana-Bold.eot");
  src: local(Verdana Bold), local(Verdana-Bold), url("../fonts/Verdana-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Verdana-Bold.woff2") format("woff2"), url("../fonts/Verdana-Bold.woff") format("woff"), url("../fonts/Verdana-Bold.ttf") format("truetype"), url("../fonts/Verdana-Bold.svg#Verdana-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: Rajdhani;
}

[class^="ar-"] {
  direction: rtl;
  font-family: Noor;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

body {
  background-image: url("../images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.siteheader {
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  display: flex;
}

.siteheader .aboutimg {
  object-fit: scale-down;
  width: 240px;
  height: 115px;
}

.siteheader .logo {
  object-fit: scale-down;
  width: 315px;
  height: 100px;
}

.sitemain {
  padding: 30px 40px;
}

.sitemain .contwrapper {
  background-color: #f3f4f4ba;
  border-radius: 36px;
  gap: 40px;
  min-height: calc(100% - 322px);
  padding: 30px 40px;
  display: flex;
  overflow-x: hidden;
}

.sitemain .contwrapper > * {
  flex: 0 0 100%;
}

.sitemain .contwrapper .sitetitle {
  align-items: center;
  display: flex;
}

.sitemain .contwrapper .sitetitle .head {
  font-size: 52px;
  font-weight: 500;
}

.sitemain .contwrapper .sitetitle .head span {
  font-size: 26px;
  font-weight: normal;
  display: block;
}

.sitemain .contwrapper .sitetitle .ar-head {
  font-size: 47px;
  font-weight: 300;
}

.sitemain .contwrapper .sitetitle .ar-head span {
  font-size: 25px;
  display: block;
}

.sitefooter {
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  display: flex;
}

.sitefooter > * {
  flex-basis: 330px;
}

.sitefooter .home-loc {
  justify-content: center;
  align-items: center;
  gap: 80px;
  display: flex;
}

.sitefooter .home-loc a {
  display: flex;
}

.sitefooter .home-loc a svg {
  fill: #363636;
  width: 50px;
  height: 50px;
}

.button-wrap .link.overallsubmit:focus-visible, .button-wrap .link.overallsubmit:focus {
  outline-offset: 5px;
  border-radius: 15px;
  animation: 1s infinite forwards outlineanimation;
  outline: 2px solid #363636 !important;
}

@keyframes outlineanimation {
  50% {
    outline-offset: 10px;
  }
}

.slide {
  transition: all .35s;
}

.slide.entry {
  align-items: center;
  display: flex;
  padding-top: 0 !important;
}

.slide.description {
  padding-top: 50px;
}

.slide.description .left-right-content-wrap .right-wrap {
  scrollbar-gutter: stable both-edges;
  height: 500px;
  padding-right: 15px;
  overflow-y: auto;
}

.left-right-content-wrap {
  justify-content: space-between;
  display: flex;
}

.left-right-content-wrap .left-wrap {
  flex: 0 0 40%;
  justify-content: center;
  align-items: center;
  width: 40%;
  display: flex;
}

.left-right-content-wrap .left-wrap img {
  max-height: 400px;
}

.left-right-content-wrap .right-wrap {
  flex: 0 0 50%;
  width: 50%;
}

.left-right-content-wrap .right-wrap p + p, .left-right-content-wrap .right-wrap h2 + p, .left-right-content-wrap .right-wrap p + h2 {
  margin-top: 5px;
}

.left-right-content-wrap .right-wrap .txt {
  padding: 0 0 20px;
}

.left-right-content-wrap .right-wrap .txt h2 {
  color: #58595b;
  padding: 0 0 5px;
  font-family: Rajdhani;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.2;
}

.left-right-content-wrap .right-wrap .txt p {
  color: #58595b;
  font-family: Rajdhani;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
}

.left-right-content-wrap.form .left-wrap {
  align-items: inherit;
  justify-content: inherit;
}

.button-wrap {
  justify-content: space-between;
  width: 100%;
  padding: 30px 0 0;
  display: flex;
}

.button-wrap .left-wrap, .button-wrap .right-wrap {
  flex: inherit;
  width: auto;
}

.button-wrap .link {
  all: unset;
  text-decoration: none;
  display: inline-block;
}

.button-wrap .btn {
  background: #f7f9f9;
  border-radius: 15px;
  align-items: center;
  min-width: 250px;
  height: 70px;
  padding: 10px 15px;
  display: flex;
}

.button-wrap .btn svg {
  width: auto;
  height: 45px;
  padding: 0 30px 0 0;
}

.button-wrap .btn .txt {
  color: #020202;
  padding: 0;
  font-family: Rajdhani;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.4;
  text-decoration: none;
}

.button-wrap .btn .txt span {
  padding: 0 0 0 10px;
}

.button-wrap .btn.btn-right {
  flex-direction: row-reverse;
}

.button-wrap .btn.btn-right svg {
  transform: rotate(180deg);
}

.button-wrap .btn.btn-right .txt span {
  padding: 0;
}

.button-wrap .btn.two-line .txt span {
  display: block;
}

.progress-outer-wrap {
  width: 100%;
}

.progress-outer-wrap .progress-wrap {
  max-width: 700px;
}

.progress-outer-wrap .progress-wrap .txt {
  color: #020202;
  margin: 0;
  font-family: Rajdhani;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
}

.progress-outer-wrap .progress-wrap .txt span {
  text-align: left;
  display: block;
}

.progress-outer-wrap .progress-wrap ul {
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.progress-outer-wrap .progress-wrap ul:before {
  width: var(--selection-width);
  content: "";
  z-index: 1;
  background: #65c5cd;
  height: 2px;
  margin: auto 0;
  transition: all .35s;
  position: absolute;
  inset: 0;
}

.progress-outer-wrap .progress-wrap ul:after {
  content: "";
  z-index: -1;
  background: #58595b;
  width: 100%;
  height: 2px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.progress-outer-wrap .progress-wrap ul li {
  z-index: 2;
  background: #58595b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  list-style: none;
  transition: all .35s .35s;
  position: relative;
}

.progress-outer-wrap .progress-wrap ul li:nth-child(2) {
  width: 60px;
  height: 60px;
}

.progress-outer-wrap .progress-wrap ul li:nth-child(3) {
  width: 80px;
  height: 80px;
}

.progress-outer-wrap .progress-wrap ul li:nth-child(4) {
  width: 100px;
  height: 100px;
}

.progress-outer-wrap .progress-wrap ul li:nth-child(5) {
  width: 120px;
  height: 120px;
}

.progress-outer-wrap .progress-wrap ul li.active {
  background-color: #65c5cd;
}

.form-wrap .logo {
  float: right;
  max-width: 600px;
  max-height: 160px;
  margin: 0 0 50px;
}

.form-wrap .fields-wrap {
  margin: 0 0 20px;
  display: flex;
  position: relative;
}

.form-wrap .fields-wrap label {
  color: #020202;
  text-align: right;
  width: 45%;
  padding: 0 30px 0 0;
  font-family: Rajdhani;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
}

.form-wrap .fields-wrap .text {
  color: #020202;
  background: #f3f4f4;
  border: 0;
  border-radius: 20px;
  width: 55%;
  padding: 5px 15px;
  font-family: Rajdhani;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  outline: 0 !important;
}

.form-wrap .fields-wrap textarea {
  resize: none;
  color: #020202;
  background: #f3f4f4;
  border: 0;
  border-radius: 20px;
  width: 55%;
  min-height: 150px;
  padding: 5px 15px;
  font-family: Rajdhani;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  outline: 0 !important;
}

.form-wrap .text.invalid, .form-wrap textarea.invalid {
  border: 1px solid red !important;
}

.form-wrap span.invalid {
  color: #fff;
  background-color: red;
  border-radius: 5px;
  padding: 2px 10px;
  font-family: Rajdhani;
  font-size: 14px;
  position: absolute;
  bottom: -15px;
  right: 0;
}

.rate-wrap {
  text-align: center;
}

.rate-wrap p {
  color: #020202;
  font-family: Rajdhani;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.4;
}

.rate-wrap p span {
  display: block;
}

.rate-wrap .smile-wrap {
  justify-content: center;
  gap: 50px;
  max-width: 775px;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
  position: relative;
}

.rate-wrap .smile-wrap svg {
  width: 225px;
  height: 225px;
}

.rate-wrap .smile-wrap svg .bg {
  fill: #0000;
}

.rate-wrap .smile-wrap svg.floatingsmiley {
  width: 245px;
  height: 245px;
  transition: all .35s;
  position: absolute;
  left: 0;
  transform: translate(-10px, -10px);
}

.rate-wrap .smile-wrap svg.floatingsmiley .fill {
  transition: all .35s;
}

.navimg {
  text-align: center;
  width: 1510px;
  margin: 0 auto;
}

.thank-you-wrap {
  padding: 100px 150px;
}

.thank-you-wrap .text-wrap {
  justify-content: space-between;
  display: flex;
}

.thank-you-wrap .text-wrap .left-wrap h2, .thank-you-wrap .text-wrap .left-wrap span {
  font-family: Rajdhani;
  font-weight: 500;
}

.thank-you-wrap .text-wrap h2 {
  color: #020202;
  padding: 0;
  font-size: 52px;
  font-weight: normal;
  line-height: 1;
}

.thank-you-wrap .text-wrap span {
  color: #020202;
  font-size: 26px;
  line-height: 1.4;
}

.thank-you-wrap .smile-wrap {
  padding: 100px 0 0;
}

.thank-you-wrap .smile-wrap ul {
  justify-content: center;
  display: flex;
}

.thank-you-wrap .smile-wrap ul li {
  list-style: none;
  position: relative;
}

.thank-you-wrap .smile-wrap ul li .emoji {
  margin: 0 0 40px;
}

.thank-you-wrap .smile-wrap ul li .txt {
  text-align: center;
}

.thank-you-wrap .smile-wrap ul li .txt p {
  color: #020202;
  font-family: Rajdhani;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.4;
}

.thank-you-wrap .smile-wrap ul li .txt p span {
  padding: 0 0 5px;
  display: block;
}

.thank-you-wrap .smile-wrap ul li.space {
  min-width: 50px;
}

.thank-you-wrap .smile-wrap ul li.space:before {
  content: "";
  background: #f4f6f6;
  width: 100%;
  height: 6px;
  position: absolute;
  top: 82px;
  left: 0;
  right: 0;
}

.dailogcontent {
  text-align: center;
  min-width: 400px;
  padding: 30px 40px;
}

.dailogcontent p {
  font-family: Rajdhani;
  font-size: 30px;
}

.dailogcontent .link {
  cursor: pointer;
  font-size: 25px;
}

.dailogcontent .link .btn {
  border: 1px solid #000;
  min-width: auto;
}

dialog {
  margin: auto;
}

dialog::backdrop {
  background-color: #0009;
}

.thank {
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 125px;
}

.thank .sitetitle [class^="ar-"] {
  margin-left: auto;
}

.thank .feedbacksmiley {
  justify-content: center;
  gap: 60px;
  margin-top: 80px;
  list-style: none;
  display: flex;
  position: relative;
}

.thank .feedbacksmiley .floatingsmiley {
  z-index: 5;
  width: 220px;
  height: 220px;
  transition: all .35s;
  position: absolute;
  top: -20px;
  left: 0;
  transform: translateX(-20px);
}

.thank .feedbacksmiley .floatingsmiley .fill, .thank .feedbacksmiley .floatingsmiley .lips {
  transition: all .35s;
}

.thank .feedbacksmiley .floatingsmiley.active {
  animation: .35s forwards scaleDown;
}

.thank .feedbacksmiley li {
  cursor: pointer;
  flex: 1 0 180px;
  position: relative;
}

.thank .feedbacksmiley li .textwrap {
  text-align: center;
  margin-top: 30px;
}

.thank .feedbacksmiley li .textwrap span {
  display: block;
}

.thank .feedbacksmiley li .textwrap span.ar-txt {
  font-size: 29px;
}

.thank .feedbacksmiley li .textwrap span.txt {
  font-size: 32px;
}

.thank .feedbacksmiley li:before {
  content: "";
  z-index: 2;
  background-color: #fff;
  width: 60px;
  height: 7px;
  position: absolute;
  top: 90px;
  right: -60px;
}

.thank .feedbacksmiley li:last-child:before {
  content: none;
}

@keyframes scaleDown {
  50% {
    transform: scale(.75)translateY(-50%);
  }
}

/*# sourceMappingURL=main.css.map */
