.slide{
    transition: all ease 0.35s;
    &.entry{
        align-items: center;
        display: flex;
        padding-top: 0!important;
    }
    &.description{
        padding-top: 50px;
        .left-right-content-wrap{
            .right-wrap{
                height: 500px;
                overflow-y: auto;
                scrollbar-gutter: stable both-edges;
                padding-right: 15px;
            }
        }
    }
}