.thank{
    margin: 0 auto;
    max-width: 1140px;
    padding-top: 125px;
    .sitetitle{
        [class^="ar-"]{
            margin-left: auto;
        }
    }
    .feedbacksmiley{
        display: flex;
        gap:60px;
        justify-content: center;
        list-style: none;
        margin-top: 80px;
        position: relative;
        .floatingsmiley{
            height: 220px;
            left: 0;
            position: absolute;
            transition: all ease 0.35s;
            transform: translateX(-20px);
            top: -20px;
            width: 220px;
            z-index: 5;
            .fill{
                transition: all ease 0.35s;
            }
            .lips{
                transition: all ease 0.35s;
            }
            &.active{
                animation: scaleDown 0.35s ease 0s 1 forwards;
            }
        }
        li{
            cursor: pointer;
            flex: 1 0 180px;
            position: relative;
            .textwrap{
                margin-top: 30px;
                text-align: center;
                span{
                    display: block;
                    &.ar-txt{
                        font-size: 29px;
                    }
                    &.txt{
                        font-size: 32px;
                    }
                }
            }
            &::before{
                content: '';
                background-color: #fff;
                height: 7px;
                position: absolute;
                right: -60px;
                top: 90px;
                width: 60px;
                z-index: 2;
            }
            &:last-child{
                &::before{
                    content: none;
                }
            }
        }
    }
}

@keyframes scaleDown {
    50%{
        transform: scale(0.75,0.75) translateY(-50%);
    }
}