@font-face {
    font-family: 'Noor';
    src: url('../fonts/Noor-Bold.eot');
    src: local('Noor Bold'), local('Noor-Bold'),
        url('../fonts/Noor-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Noor-Bold.woff2') format('woff2'),
        url('../fonts/Noor-Bold.woff') format('woff'),
        url('../fonts/Noor-Bold.ttf') format('truetype'),
        url('../fonts/Noor-Bold.svg#Noor-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noor';
    src: url('../fonts/Noor-Light.eot');
    src: local('Noor Light'), local('Noor-Light'),
        url('../fonts/Noor-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Noor-Light.woff2') format('woff2'),
        url('../fonts/Noor-Light.woff') format('woff'),
        url('../fonts/Noor-Light.ttf') format('truetype'),
        url('../fonts/Noor-Light.svg#Noor-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Bold.eot');
    src: local('Rajdhani Bold'), local('Rajdhani-Bold'),
        url('../fonts/Rajdhani-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-Bold.woff2') format('woff2'),
        url('../fonts/Rajdhani-Bold.woff') format('woff'),
        url('../fonts/Rajdhani-Bold.ttf') format('truetype'),
        url('../fonts/Rajdhani-Bold.svg#Rajdhani-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Medium.eot');
    src: local('Rajdhani Medium'), local('Rajdhani-Medium'),
        url('../fonts/Rajdhani-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-Medium.woff2') format('woff2'),
        url('../fonts/Rajdhani-Medium.woff') format('woff'),
        url('../fonts/Rajdhani-Medium.ttf') format('truetype'),
        url('../fonts/Rajdhani-Medium.svg#Rajdhani-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/Helvetica-Light.eot');
    src: local('Helvetica Light'), local('Helvetica-Light'),
        url('../fonts/Helvetica-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Helvetica-Light.woff2') format('woff2'),
        url('../fonts/Helvetica-Light.woff') format('woff'),
        url('../fonts/Helvetica-Light.ttf') format('truetype'),
        url('../fonts/Helvetica-Light.svg#Helvetica-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Regular.eot');
    src: local('Rajdhani Regular'), local('Rajdhani-Regular'),
        url('../fonts/Rajdhani-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-Regular.woff2') format('woff2'),
        url('../fonts/Rajdhani-Regular.woff') format('woff'),
        url('../fonts/Rajdhani-Regular.ttf') format('truetype'),
        url('../fonts/Rajdhani-Regular.svg#Rajdhani-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verdana';
    src: url('../fonts/Verdana-Bold.eot');
    src: local('Verdana Bold'), local('Verdana-Bold'),
        url('../fonts/Verdana-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Verdana-Bold.woff2') format('woff2'),
        url('../fonts/Verdana-Bold.woff') format('woff'),
        url('../fonts/Verdana-Bold.ttf') format('truetype'),
        url('../fonts/Verdana-Bold.svg#Verdana-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

*{
    font-family: 'Rajdhani';
}

[class^="ar-"]{
    direction: rtl;
    font-family: 'Noor';
}
