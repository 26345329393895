$fontFamily: 'Rajdhani';
$primary-color: #020202;
$secondary-color: #58595B;


.left-right-content-wrap {
    display: flex;
    justify-content: space-between;

    .left-wrap {
        width: 40%;
        flex: 0 0 40%;
        align-items: center;
        justify-content: center;
        display: flex;

        img {
            max-height: 400px;
        }
    }

    .right-wrap {
        width: 50%;
        flex: 0 0 50%;
        p + p,h2 + p,p + h2{
            margin-top: 5px;
        }

        .txt {
            padding: 0 0 20px 0;

            h2 {
                font-family: $fontFamily;
                font-weight: bold;
                font-size: 22px;
                color: $secondary-color;
                line-height: 1.2;
                padding: 0 0 5px 0;
            }

            p {
                font-family: $fontFamily;
                font-weight: 500;
                font-size: 21px;
                color: $secondary-color;
                line-height: 1.2;
            }
        }
    }

    &.form {
        .left-wrap {
            align-items: inherit;
            justify-content: inherit;
        }
    }
}

.button-wrap {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0 0;
    width: 100%;

    .left-wrap {
        width: auto;
        flex: inherit;
    }

    .right-wrap {
        width: auto;
        flex: inherit;
    }

    .link {
        all: unset;
        display: inline-block;
        text-decoration: none;
    }

    .btn {
        display: flex;
        background: #F7F9F9;
        border-radius: 15px;
        padding: 10px 15px;
        align-items: center;
        min-width: 250px;
        height: 70px;

        svg {
            width: auto;
            height: 45px;
            padding: 0 30px 0 0;
        }

        .txt {
            font-family: $fontFamily;
            font-weight: bold;
            font-size: 22px;
            color: $primary-color;
            line-height: 1.4;
            text-decoration: none;
            padding: 0px;

            span {
                padding: 0 0 0 10px;
            }
        }

        &.btn-right {
            flex-direction: row-reverse;

            svg {
                transform: rotate(180deg);
            }

            .txt {
                span {
                    padding: 0px;
                }
            }
        }

        &.two-line {
            .txt {
                span {
                    display: block;
                }
            }
        }
    }
}


.progress-outer-wrap {
    width: 100%;

    .progress-wrap {
        max-width: 700px;
        .txt {
            font-family: $fontFamily;
            font-weight: bold;
            font-size: 24px;
            color: $primary-color;
            line-height: 1.2;
            margin: 0 0 0px 0;

            span {
                display: block;
                text-align: left;
            }
        }

        ul {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 1;

            &:before {
                width: var(--selection-width);
                height: 2px;
                content: '';
                position: absolute;
                inset: 0;
                background: #65C5CD;
                margin: auto 0;
                z-index: 1;
                transition: all ease 0.35s;
            }

            &:after {
                width: 100%;
                height: 2px;
                content: '';
                position: absolute;
                inset: 0;
                background: $secondary-color;
                margin: auto;
                z-index: -1;
            }

            li {
                border-radius: 50%;
                width: 40px;
                height: 40px;
                background: $secondary-color;
                list-style: none;
                position: relative;
                transition: all ease 0.35s 0.35s;
                z-index: 2;

                &:nth-child(2) {
                    width: 60px;
                    height: 60px;
                }

                &:nth-child(3) {
                    width: 80px;
                    height: 80px;
                }

                &:nth-child(4) {
                    width: 100px;
                    height: 100px;
                }

                &:nth-child(5) {
                    width: 120px;
                    height: 120px;
                }
                &.active{
                    background-color: #65C5CD;
                }
            }
        }
    }
}

.form-wrap {
    .logo {
        float: right;
        margin: 0 0 50px 0;
        max-width: 600px;
        max-height: 160px;
    }

    .fields-wrap {
        display: flex;
        margin: 0px 0 20px 0;
        position: relative;

        label {
            width: 45%;
            font-family: $fontFamily;
            font-weight: 500;
            font-size: 22px;
            color: $primary-color;
            line-height: 1.4;
            padding: 0 30px 0 0;
            text-align: right;
        }

        .text {
            background: #F3F4F4;
            border-radius: 20px;
            border: 0px;
            width: 55%;
            font-family: $fontFamily;
            font-weight: 500;
            font-size: 22px;
            color: $primary-color;
            line-height: 1.4;
            outline: 0 !important;
            padding: 5px 15px;
        }

        textarea {
            background: #F3F4F4;
            border-radius: 20px;
            border: 0px;
            resize: none;
            width: 55%;
            font-family: $fontFamily;
            font-weight: 500;
            font-size: 22px;
            color: $primary-color;
            line-height: 1.4;
            outline: 0 !important;
            padding: 5px 15px;
            min-height: 150px;
        }
    }

    .text.invalid,textarea.invalid {
        border: 1px solid #ff0000!important;
    }
    span.invalid{
        background-color: #ff0000;
        border-radius: 5px;
        bottom: -15px;
        color: #ffffff;
        font-size: 14px;
        font-family: 'Rajdhani';
        padding: 2px 10px;
        position: absolute;
        right: 0;
    }
}

.rate-wrap {
    text-align: center;

    p {
        font-family: $fontFamily;
        font-weight: 500;
        font-size: 28px;
        color: $primary-color;
        line-height: 1.4;

        span {
            display: block;
        }
    }

    .smile-wrap {
        display: flex;
        padding: 100px 0;
        justify-content: center;
        gap:50px;
        position: relative;
        max-width: 775px;
        margin: 0 auto;

        svg {
            height: 225px;
            width: 225px;
            .bg{
                fill: transparent;
            }
            &.floatingsmiley{
                height: 245px;
                left: 0;
                position: absolute;
                transform: translate(-10px, -10px);
                transition: all ease 0.35s;
                width: 245px;
                .fill{
                    transition: all ease 0.35s;
                }
            }
        }
    }
}

.navimg {
    text-align: center;
    width: 1510px;
    margin: 0 auto;
}


.thank-you-wrap {
    padding: 100px 150px;

    .text-wrap {
        display: flex;
        justify-content: space-between;

        .left-wrap {
            h2 {
                font-family: $fontFamily;
                font-weight: 500;
            }

            span {
                font-family: $fontFamily;
                font-weight: 500;
            }
        }

        .right-wrap {}

        h2 {
            font-size: 52px;
            color: $primary-color;
            line-height: 1;
            padding: 0 0 0 0;
            font-weight: normal;
        }

        span {
            font-size: 26px;
            color: $primary-color;
            line-height: 1.4;
        }
    }

    .smile-wrap {
        padding: 100px 0 0 0;

        ul {
            display: flex;
            justify-content: center;

            li {
                list-style: none;
                position: relative;

                .emoji {
                    margin: 0 0 40px 0;

                    svg {}
                }

                .txt {
                    text-align: center;

                    p {
                        font-family: $fontFamily;
                        font-weight: 500;
                        font-size: 32px;
                        color: $primary-color;
                        line-height: 1.4;

                        span {
                            display: block;
                            padding: 0 0 5px 0;
                        }
                    }
                }

                &.space {
                    min-width: 50px;

                    &:before {
                        position: absolute;
                        top: 82px;
                        left: 0px;
                        right: 0px;
                        content: '';
                        width: 100%;
                        height: 6px;
                        background: #F4F6F6;
                    }
                }
            }
        }
    }
}