.dailogcontent{
    min-width: 400px;
    padding: 30px 40px;
    text-align: center;
    p{
        font-size: 30px;
        font-family: 'Rajdhani';
    }
    .link{
        cursor: pointer;
        font-size: 25px;
        .btn{
            border: 1px solid #000000;
            min-width: auto;
        }
    }
}

dialog{
    margin: auto;
    &::backdrop{
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
}