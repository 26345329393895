*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
img{
    height: auto;
    max-width: 100%;
    display: block;
}
body{
    background-image: url(../images/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
.siteheader{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
    .aboutimg{
        height: 115px;
        object-fit: scale-down;
        width: 240px;
    }
    .logo{
        height: 100px;
        object-fit: scale-down;
        width: 315px;
    }
}
.sitemain{
    padding: 30px 40px;
    .contwrapper{
        background-color: rgba(243,244,244,0.73);
        border-radius: 36px;
        display: flex;
        gap: 40px;
        min-height: calc(100% - (175px + 87px + 60px));
        overflow-x: hidden;
        padding: 30px 40px;
        & > *{
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
        }
        .sitetitle{
            display: flex;
            align-items: center;
            .head{
                font-size: 52px;
                font-weight: 500;
                span{
                    display: block;
                    font-size: 26px;
                    font-weight: normal;
                }
            }
            .ar-head{
                font-size: 47px;
                font-weight: 300;
                span{
                    display: block;
                    font-size: 25px;
                }
            }
        }
    }
}
.sitefooter{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;
    & > *{
        flex-basis: 330px;
    }
    .home-loc{
        align-items: center;
        display: flex;
        justify-content: center;
        gap: 80px;
        a{
            display: flex;
            svg{
                fill:#363636;
                height: 50px;
                width: 50px;
            }
        }
    }
}

.button-wrap .link.overallsubmit{
    &:focus-visible,&:focus{
        animation: outlineanimation 1s ease 0s infinite forwards;
        border-radius: 15px;
        outline: 2px solid #363636!important;
        outline-offset: 5px;
    }
}

@keyframes outlineanimation {
    50%{
        outline-offset: 10px;
    }
}